import axios from 'axios';

export default {
  isBrowser() { return typeof window !== 'undefined'; },

  setUser(userData) {
    if (this.isBrowser()) {
      window.localStorage.setItem('lu', JSON.stringify(userData));
    }
  },

  getUser() {
    if (this.isBrowser()) {
      const userData = window.localStorage.getItem('lu');
      return userData ? JSON.parse(userData) : null;
    }

    return null;
  },

  login(username, password) {
    return axios.post(process.env.GATSBY_LOGIN_POST_URL, {
      username,
      password,
    }).catch((error) => {
      return error.response;
    })
  },

  logout() {
    window.localStorage.removeItem('lu');
  },

  isLoggedIn() {
    const user = this.getUser();
    return user !== null && user.token;
  },
}
